import '../css/register.scss';
import {liveLoadData, filterLoad, submitData, dataFromForm, getData} from './lib/livedata';
import {strToArray} from './lib/helpers';
import {getServerUrl} from './lib/url';
import {minDays, shortDays, longDays, shortMonths, longMonths, dateMidFormat, dateMidScheme} from './lib/locales';
import {createCookie, checkCookie, readCookie} from './lib/cookie';

$(document).ready(function() {

	console.log("WCZYTANO REJESTRACJE");




	
	// var $form = $('#registration-form');
	//
	// $form.on("change", 'input[name="cityChoosenId"]', function(){
	// 	$('.city-results').append('<input type="disabled" name="city[]" class="btn btn-outline-primary ml-1 mr-1 choosen-city" value="'+$('input[name="cityChoosenId"]:checked').val()+'"/>');
	// });
	//
	// $form.on("click",'.choosen-city', function(){
	// 	$(this).remove();
	// });
	//
});
